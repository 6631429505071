@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap"); /* Import pixel-like font */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Press Start 2P", cursive; /* Apply pixel-like font everywhere */
  background-color: #f0f0f0;
  color: #333;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: #bbc0ee;
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
}

.pixel-font {
  font-family: "Press Start 2P", cursive; /* Pixel-like font */
  font-size: 2rem;
}

.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border: none;
  background-color: #bbc0ee;

  background-size: cover;
  padding: 20px;
}

.App-main.with-background {
  background-image: url("../public/mountain_bg_gizmoat.png");
}

.main-character-image {
  max-width: 80px;
  width: 20vw;
  margin: 20px 0;
}

.game-description {
  font-size: 1.2rem;
  color: white;
  padding: 0 20px;
  text-align: center;
}

.App-footer {
  background-color: #bbc0ee;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 10px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Privacy Policy Page Styles */
.privacy-container {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  text-align: left;
}

.privacy-heading {
  font-size: 1.5rem;
  margin: 20px 0 10px;
  color: #fff;
}

.privacy-subheading {
  font-size: 1.2rem;
  margin: 15px 0 10px;
  color: #fff;
}

.privacy-text {
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-list {
  margin: 10px 0 10px 20px;
  color: #fff;
}

.privacy-list li {
  margin-bottom: 10px;
  font-size: 0.9rem;
  line-height: 1.6;
}

.privacy-link {
  color: #fff;
  text-decoration: underline;
  word-break: break-all;
}

.privacy-section {
  margin: 20px 0;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .privacy-container {
    padding: 15px;
  }

  .privacy-heading {
    font-size: 1.3rem;
  }

  .privacy-subheading {
    font-size: 1.1rem;
  }

  .privacy-text,
  .privacy-list li {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .privacy-container {
    padding: 10px;
  }

  .privacy-heading {
    font-size: 1.2rem;
  }

  .privacy-subheading {
    font-size: 1rem;
  }

  .privacy-text,
  .privacy-list li {
    font-size: 0.75rem;
  }

  .pixel-font {
    font-size: 1.5rem;
  }
}
